import type { ComputedRef, Ref, UnwrapRef } from 'vue'
import isEqual from 'lodash/isEqual'
import { isBoolean, isNumber, isPrimitive } from 'types/generic/data-type.guards'
import type { Pattern, ValidationRules } from 'types/validation'
import type { Environment } from 'types/environment'
import { postCodes } from '~/data/patterns/postcodes'
import { latinRegex } from '~/data/patterns/latin'
import { phoneRegex } from '~/data/patterns/phone'
import { emailRegex } from '~/data/patterns/email'
import { mustContainNumbersRegex } from '~/data/patterns/mustContainNumbers'

export interface ValidationUpdate<Key = string, Value = string | number | boolean> {
  name: Key
  value: Value
  valid: boolean
  hasChanged: boolean
  eventType: 'input' | 'change'
}
interface ValidationState {
  valid: boolean
  errors: string[]
  hasChanged: boolean
}

type StaticValidator<Value = string | number | boolean> = (value: Value) => boolean
type DynamicValidator<RuleType = string | number | boolean, ValueType = RuleType> = (
  rule: RuleType,
  value: ValueType
) => boolean

interface Validators {
  isRequired: StaticValidator<boolean | string | number>
  isMin: DynamicValidator<number, string | number>
  isMax: DynamicValidator<number, string | number>
  isEmail: StaticValidator<string>
  isPhone: StaticValidator<string>
  conformsToPattern: DynamicValidator<Pattern, string>
  isPostCode: DynamicValidator<Environment.CountryCode, string>
  isLatin: StaticValidator<string>
  isLatinAndContainsNumber: StaticValidator<string>
}

export const validators: Validators = {
  isMin(rule, value) {
    if (isNumber(value))
      return value >= rule

    return value.length >= rule
  },
  isMax(rule, value) {
    return isNumber(value) ? value <= rule : value.length <= rule
  },
  isRequired(value) {
    return !!value
  },
  isEmail(value: string) {
    return emailRegex.test(value)
  },
  isPhone(value: string) {
    return phoneRegex.test(value)
  },
  isLatin(value: string) {
    return latinRegex.test(value)
  },
  isLatinAndContainsNumber(value: string) {
    return latinRegex.test(value) && mustContainNumbersRegex.test(value)
  },
  conformsToPattern({ regexp }: Pattern, value: string) {
    const re = new RegExp(regexp)
    return re.test(value)
  },
  isPostCode(countryCode, value) {
    const postcode = postCodes[countryCode]
    if (!postcode)
      return Boolean(value)

    const re = new RegExp(postcode.regexp)
    return re.test(value)
  },
}

export function createRuleSet(rules: ValidationRules) {
  return {
    min: rules.min?.value || 0,
    max: rules.max?.value || 255,
    patterns: rules.patterns || [{ regexp: '.*', example: 'abc123' }],
    pattern: rules.pattern?.value || 'email',
    required: rules.required?.value || false,
  }
}

/**
 * Title is required when using regexp pattern in an input.
 * @returns An array with [RegExp as string, title as string]
 */
function getRegExpPattern(rules: Ref<ValidationRules> | ValidationRules, name: string) {
  const { $t } = useNuxtApp()
  const rulesValue = unref(rules)
  const { pattern } = rulesValue
  const regexToString = (regex: RegExp) => regex.toString().replace(/^\/|\/$/g, '')

  if (pattern?.value === 'postcode') {
    const country = pattern.data?.country

    if (!country) {
      throw new Error(
        `No country specified for postcode validation in ${
          name
        }.`
        + `\n`
        + `Please specify a country in the pattern.data.country object.`,
      )
    }

    const postcode = postCodes[country]

    if (postcode)
      return [regexToString(postcode.regexp), postCodes[country].errorMessage]
  }

  if (pattern?.value === 'latin')
    return [regexToString(latinRegex), $t('errorNonLatin')]

  if (pattern?.value === 'phone')
    return [regexToString(phoneRegex), $t('errorPhonePatternNotValid')]

  if (pattern?.value === 'email')
    return [regexToString(emailRegex), $t('errorEmailPatternNotValid')]

  return [undefined, undefined]
}

export function useValidation<Value>({
  rules,
  name,
  emit,
  value,
  eventType,
  forceShowValidation,
  defaultValue,
}: {
  name: string
  eventType?: ValidationUpdate['eventType']
  rules: Ref<ValidationRules> | ValidationRules
  emit: {
    (e: 'update', value: ValidationUpdate): void
  }
  forceShowValidation: Ref<boolean> | ComputedRef<boolean>
  value: Ref<Value> | ComputedRef<Value>
  defaultValue?: Value
}) {
  const { $t } = useNuxtApp()
  eventType = eventType || 'change'
  const currentValue = ref<Value>(value.value)
  const rulesValue = unref(rules)
  const ruleset = ref(createRuleSet(rulesValue))
  const validation = reactive<ValidationState>({
    errors: [],
    valid: true,
    hasChanged: !!value.value,
  })

  const patternAndTitle = computed(() => getRegExpPattern(rules, name))

  watch(
    value,
    (current) => {
      currentValue.value = current as UnwrapRef<Value>
    },
    {
      immediate: true,
    },
  )

  const validate = (unwrappedValue: UnwrapRef<Value>, rulesValue = unref(rules)) => {
    validation.errors = []

    if (unwrappedValue === defaultValue)
      return

    if (!isPrimitive(unwrappedValue))
      throw new TypeError(`Invalid value type for validation: ${typeof unwrappedValue} in ${name}`)

    const value = unwrappedValue as string | number | boolean

    const numericValue = isNumber(value)
    const booleanValue = isBoolean(value)

    if (!validation.hasChanged && !!value)
      validation.hasChanged = true

    // ! Todo: Remove the ridiculous typecasting once Nuxt updates its typescript dependency

    try {
      if (rulesValue.required?.value && !validators.isRequired(value))
        validation.errors.push(rulesValue.required?.errorMessage || $t('errorRequired'))

      if (booleanValue)
        return // no more boolean validation, skip to finally

      if (rulesValue.min && !validators.isMin(rulesValue.min.value, value as string | number)) {
        const errorKey = isNumber(value) ? 'errorTooSmall' : 'errorTooShortChar'
        validation.errors.push(
          rulesValue.min?.errorMessage
          || $t(errorKey, { minLimit: rulesValue.min.value.toString() }),
        )
      }

      if (rulesValue.max && !validators.isMax(rulesValue.max.value, value as string | number)) {
        const errorKey = isNumber(value) ? 'errorTooLarge' : 'errorTooManyChar'
        validation.errors.push(
          rulesValue.max.errorMessage || $t(errorKey, { maxLimit: rulesValue.max.value.toString() }),
        )
      }

      if (numericValue)
        return // Skip to finally

      if (rulesValue.pattern?.value === 'email' && !validators.isEmail(value as string))
        validation.errors.push(rulesValue.pattern?.errorMessage || $t('errorEmailPatternNotValid'))

      if (value && rulesValue.pattern?.value === 'latin' && !validators.isLatin(value as string))
        validation.errors.push($t('errorNonLatin'))

      if (value && rulesValue.pattern?.value === 'latinAndContainsNumber' && !validators.isLatinAndContainsNumber(value as string))
        validation.errors.push($t('errorLatinAndContainsNumber'))

      if (rulesValue.pattern?.value === 'phone' && !validators.isPhone(value as string))
        validation.errors.push(rulesValue.pattern?.errorMessage || $t('errorPhonePatternNotValid'))

      if (rulesValue.pattern?.value === 'postcode') {
        const country = rulesValue.pattern.data.country

        if (value && !validators.isPostCode(country, value as string)) {
          validation.errors.push(
            rulesValue.pattern?.errorMessage || postCodes[country].errorMessage,
          )
        }
      }
    }
    catch (e) {
      console.error(`ValidationError`, e)
    }
    finally {
      const { hasChanged, errors } = validation

      validation.valid = errors.length === 0 && hasChanged

      validation.errors.length ? (validation.valid = false) : (validation.valid = true)

      emit('update', {
        value,
        eventType,
        valid: validation.valid,
        hasChanged: validation.hasChanged,
        name: name || 'validatedFormElement',
      } as ValidationUpdate)
    }
  }

  if (forceShowValidation?.value || value.value) {
    onMounted(() => {
      validate(currentValue.value)
    })
  }

  watch(forceShowValidation, (forceShowValidation) => {
    if (!forceShowValidation)
      return
    validate(currentValue.value)
  })

  watch(currentValue, newValue => validate(newValue))

  isRef(rules)
  && watch(rules, (rules, oldRules) => {
    if (!isEqual(rules, oldRules)) {
      ruleset.value = createRuleSet(rules)
      if (!rules.required?.value || currentValue.value)
        validate(currentValue.value, unref(rules))
    }
  })

  return {
    ruleset,
    validation,
    currentValue,
    pattern: computed(() => patternAndTitle.value[0]),
    title: computed(() => patternAndTitle.value[1]),
  }
}
