import { validators } from '~/lib/validation'
import type { Address } from '~/types/models/contact.model'

const Errors = ['errorNonLatin', 'errorPostcodePatternNotValid', 'errorPhonePatternNotValid', 'errorEmailPatternNotValid', 'errorRequired', 'errorTooManyChar'] as const
type Error = typeof Errors[number]

interface ReturnType {
  valid: boolean
  errors: Error[]
  errorsPerKey: Record<keyof Address, Error[]>
}

const Rules = ['required', 'min', 'max', 'email', 'phone', 'latin', 'latinAndContainsNumber', 'postCode'] as const
type Rule = typeof Rules[number]

export type Rules = Rule[]

interface Options {
  includeRules: Rule[]
}

const keyRuleMap: Record<keyof Address, Array<Rule>> = {
  city: ['required', 'latin'],
  street0: ['required', 'latin'],
  street1: ['latin'],
  region: ['required', 'latin'],
  country: ['required'],
  postCode: ['required', 'postCode'],
  lastName: ['required', 'latin'],
  firstName: ['required', 'latin'],
}

const MaxRuleValues: Record<keyof Address, number> = {
  city: 30,
  street0: 30,
  street1: 30,
  region: 30,
  postCode: 30,
  lastName: 250,
  firstName: 250,
  country: 30,
}

export function validateAddress(address: Address, options: Options): ReturnType {
  const errors: Set<Error> = new Set()
  const errorsPerKey: Record<keyof Address, Error[]> = {
    city: [],
    street0: [],
    street1: [],
    region: [],
    country: [],
    postCode: [],
    lastName: [],
    firstName: [],
  }
  const rules = options.includeRules

  function ruleIsIncludedInKey(key: keyof Address, rule: Rule): boolean {
    return rules.includes(rule) && keyRuleMap[key]?.includes(rule)
  }

  for (const _key in address) {
    const key = _key as keyof Address
    const value = address[key]

    try {
      if (ruleIsIncludedInKey(key, 'latin') && value && !validators.isLatin(value)) {
        errors.add(Errors[0])
        errorsPerKey[key].push(Errors[0])
      }

      if (ruleIsIncludedInKey(key, 'required') && value && !validators.isRequired(value)) {
        errors.add(Errors[4])
        errorsPerKey[key].push(Errors[4])
      }

      if (ruleIsIncludedInKey(key, 'max') && value && !validators.isMax(MaxRuleValues[key as keyof Address], value)) {
        errors.add(Errors[5])
        errorsPerKey[key].push(Errors[5])
      }

      if (ruleIsIncludedInKey(key, 'postCode') && value && address.country && !validators.isPostCode(address.country, value)) {
        errors.add(Errors[2])
        errorsPerKey[key].push(Errors[2])
      }
    }
    catch (e) {
      console.error(`ValidationError`, e)
    }
  }

  const valid = errors.size === 0

  return {
    valid,
    errors: Array.from(errors),
    errorsPerKey,
  }
}
